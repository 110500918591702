import React from 'react'
import {getConversion} from '../../api/images'

const Template2 = ({ page }) => {
    const { media, text1, text2, text3 } = page

    return (
        <div className='row align-items-center'>
            <div className='col-12'>
                {text1 && text1.split('\n').map((line,index) => (
                    <p key={'text1'+index}>
                        <div dangerouslySetInnerHTML={{ __html: line }} />
                    </p>
                ))}
            </div>
            <div className='col-lg-6 col-md-12 mt-3'>
                {text2 && text2.split('\n').map((line,index) => (
                    <p key={'text2'+index}>
                        <div dangerouslySetInnerHTML={{ __html: line }} />
                    </p>
                ))}
            </div>
            <div className='col-lg-6 col-md-12 mt-3'>
                {media.length > 0 && media[0] ?
                    <img src={getConversion(page.media[0])} alt={media[0].name} className='p-3 img-fluid' />
                    :
                    null
                }
            </div>
            <div className='col-lg-6 col-md-12 mt-3'>
                {media.length > 0 && media[1] ?
                    <img src={getConversion(page.media[1])} alt={page.media[1].name} className='p-3 img-fluid' />
                    :
                    null
                }
            </div>
            <div className='col-lg-6 col-md-12 mt-3'>
                {text3 && text3.split('\n').map((line,index) => (
                    <p key={'text3'+index}>
                        <div dangerouslySetInnerHTML={{ __html: line }} />
                    </p>
                ))}
            </div>
        </div>
    )
}

export default Template2